.getstarted_navbar{
    position: absolute;
    top: 0;
    background-color: rgba(21, 18, 22, 0.5);
    width: 100%;
    /* z-index: 1; */
}

.bg_image{
    background-image: url("../Images/get_started_bg2.png");
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    background-size: cover;
    background-repeat: no-repeat;
    width: auto;
    height: 90vh;
    color: #FFFFFF;
    /* transition: opacity 3s ease-in-out; */
   
}
@media only screen and (min-width: 940px) and (max-width: 1075px){
    .hero_text{
        font-size: 3rem !important;
        line-height: 80px !important;
    } .nightmares_text{
        font-size: 2.5rem !important;
        line-height: 50px;
    }
   
}
@media only screen and (min-width: 776px) and (max-width: 939px){
    .hero_text{
        font-size: 3rem !important;
        line-height: 70px !important;
    }
    .nightmares_text{
        font-size: 2.5rem !important;
        line-height: 50px;
    }
    .bg_image{
        background-image: url("../Images/ipad_bg.png") !important;
        text-align: center;
        justify-content: center;
        align-items: center;
        display: flex;
        background-size: cover;
        background-repeat: no-repeat;
        width: auto;
        height: 90vh;
        color: #FFFFFF;
       
    }
   
}
@media only screen and (min-width: 600px) and (max-width: 775px){
    .hero_text{
        font-size: 2.6rem !important;
        line-height: 60px !important;
    }
    .nightmares_text{
        font-size: 2rem !important;
    }
    .bg_image{
        background-image: url("../Images/mobile_bg.png") !important;
        text-align: center;
        justify-content: center;
        align-items: center;
        display: flex;
        background-size: cover;
        background-repeat: no-repeat;
        width: auto;
        height: 90vh;
        color: #FFFFFF;
       
    }
}
@media only screen and (min-width: 360px) and (max-width: 600px){
    .hero_text{
        font-size: 2rem !important;
        line-height: 50px !important;
    }
    .nightmares_text{
        font-size: 1.5rem !important;
    }
    .bg_image{
        background-image: url("../Images/mobile_bg.png") !important;
        text-align: center;
        justify-content: center;
        align-items: center;
        display: flex;
        background-size: cover;
        background-repeat: no-repeat;
        width: auto;
        height: 90vh;
        color: #FFFFFF;
       
    }
}