@media screen and (max-width:768px) {
    .service_mobile_responsive{
        padding:10px!important;
    }
    .our_service_mobile{
        font-size: 1.5rem!important;
        font-weight: 900!important;
        padding: 0%!important;
        line-height: 40px!important;
    }
    .our_services_text{
        font-size: 0.7rem!important;
    }
}