#learn-earn{
  scroll-margin-top: 120px;
}

.video-wrapper {

    position: relative;
    border-radius: 20px;
    overflow: hidden;
  }
  .video-wrapper video {
    position: absolute;
    top: 0;
    z-index: -1;
    width: 100%;
    height: auto;
  }
  .video {
    display: flex;

    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right, #9d4edd, rgba(255, 255, 255, 0));
    color: #fff;
    object-fit: cover;
    background-size: cover;
  }
  
 

  .video-body div {
    font-size: 1.2rem;
    font-weight: 300;
    
  }
  
  
  /* responsive for tablets */
  @media screen and (max-width: 768px) {
    .hero-wrapper {
      height: 50vh;
    }
    .hero {
      height: 50vh;
    }
    .hero-body h1 {
      font-size: 7rem;
    }
  
    .hero-body p {
      font-size: 1rem;
      letter-spacing: 3px;
      margin-top: -20px;
    }
   
  }
  
  
  /* responsive for mobile phones */
  @media screen and (max-width:576px) {
    .hero-wrapper {
      height: 40vh;
    }
    .hero {
      height: 40vh;
    }
    .hero-body h1 {
      font-size: 3.5rem;
    }
  
    .hero-body p {
      font-size: 0.8rem;
      letter-spacing: 0px;
      margin-top: -10px;
    }
    /* .video {
      display: flex;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(to bottom, #9d4edd, rgba(255, 255, 255, 0));
      color: #fff;
      object-fit: cover;
      background-size: cover;
    } */
  }
  
