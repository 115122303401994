.accordion {
    max-width: 600px;
    margin: 1rem auto;
    border: 1px solid #D7B6F1;
    border-radius: 8px;
  }
  
  .accordion-title {
    display: flex;
    font-size: 0.9rem;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;
    /* background-color: #21aeca; */
  }
  .accordion-content{
    overflow: hidden;
  }
  /* .accordion-title:hover {
    background-color: #3ab4cc;
  }
   */
  .accordion-title,
  .accordion-content {
    padding: 0.8rem;

  }
  
  /* .accordion-content {
    background-color: #39b9d2;
  } */
  
  @media screen and (max-width: 700px) {
    body {
      font-size: 18px;
    }
  
    .accordion {
      width: 90%;
      margin-top: 0%;
      padding-top: 0%
    }
  }