@media screen and (max-width:991px) {
    .esteemed_image{
        width: 100px;
    }
}
@media screen and (max-width:767px) {
    .clients div{
       justify-content: space-between m !important;
       margin-top: 5% !important;
    }
    .clients_heading{
        font-size: 2.2rem !important;
    }
    
}