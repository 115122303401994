@media only screen and (max-width: 767px) {
  .footer_text div {
    font-size: 2rem !important;
    text-align: left !important;
    justify-content: left !important;
  }
  .privacy_policy_text {
    font-size: 0.8rem;
    margin-bottom: 20px;
  }
}
.follow_links :hover{
  color: aliceblue !important;
  background-color: aqua !important;
}
