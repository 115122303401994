@media screen and (max-width:991px) {
    .hero_mobile{
        text-align: center;
    }
    .trial_button{
        align-items: center;
        text-align: center;
        justify-content: center;
        white-space: nowrap;
    }
    .get_fifteen_mobile{
        width: 100% !important;;
    }
   
}
@media only screen and (max-width: 767px) {
.mobile_hero_responisve{
    padding: 10px !important;
}
    .herosection_text{
        font-size: 1.5rem!important;
        font-weight: 900!important;
        padding: 0%!important;
        line-height: 40px!important;
    }
    .get_fifteen_mobile{
        width: 100% !important;;
    }
    .para_mobile_text{
        font-size: 0.7rem!important;
        width:100%;
    }
    .hero_img{
        width: 100% !important;
    }
  }
