.header {
  position: fixed;
  /* height: 100px; */
  width: 100%;
  top: 0%;
  left: 0;
  /* z-index: 1; */
  transition: 0.3s ease-in;
  /* padding: 12px; */
  background-color: white;
}

.header .navbar {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  max-width: 1240px;
  height: 100%;
}

.header .nav-menu a {
  color: #ffffff;
}

.header .navbar img {
  width: 250px;
  height: auto;
}

.header .nav-menu {
  display: flex;
  align-items: center;
}

.header .nav-item {
  padding-left: 20px;
  padding-top: 20px;
  font-weight: 400;
}
.nav-item .tab_text {
  font-size: 0.9rem;
  
}
.nav-item a{
  font-size: 0.9rem;
  font-weight: 400;

}

.hamburger {
  display: none;
}
.whoare {
  font-size: 0.9rem;
  font-weight: 400;
}
@media screen and (max-width: 1200px) and (min-width: 992px) {
  .header .navbar {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    /* max-width: 1400px !important; */
    height: 100%;
  }
  .header .nav-item {
    padding-left: 10px !important;
    padding-top: 20px;
    font-weight: 400;
  }
  .nav-item a {
    font-size: 0.7rem !important; 
  }
  .whoare {
    font-size: 0.7rem !important;
  }
}
@media screen and (max-width: 991px) {
  .header {
    max-width: 100%;
    background-color: #ffffff;
    background-size: cover;
    max-height: 100%;
  }

  .header .navbar {
    max-width: 100%;
  }

  .hamburger {
    display: block;
  }

  .nav-menu {
    position: fixed;
    left: -100%;
    top: 45px;
    flex-direction: column;
    background-color: white;
    width: 100%;
    height: 100vh;
    z-index: 999;
    text-align: center;
    transition: 0.3s;
  }

  .nav-menu.active {
    left: 0;
    color: blueviolet !important;
  }

  .nav-item {
    padding: 1rem 0;
    margin-left: -10%;
  }
  a {
    text-decoration: none;
    color: white;
    text-align: center;
  }
  .nav-item a {
    left: 10%;
  }
  .header .navbar img {
    width: 150px;
  }
}
@media only screen and (min-width: 940px) and (max-width: 1075px) {
  /* .header .nav-item {
      padding-left: 10px;
      padding-top: 20px;
      font-weight: 600;
    }
    .nav-item a {
      font-size: 0.7rem;
    } */
}
