.get_started nav {
  padding: 12px;
  position: sticky;
  top: 0;
  line-height: 3em;
  text-align: center;
  background-color: white;
  z-index: 1;
  /* color: white; */
  /* font-family: sans-serif; */
  transition: transform 150ms ease-in-out;
}

nav.nav-bar--hidden {
  transform: translateY(-100%);
}
