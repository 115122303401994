@media screen and (max-width:760px) {
    .who_we_are_mobile_responsive{
        padding: 10px !important;
    }
    .professional_text{
        margin: 0%!important;
    }
    .professional_text>div{
        font-size: 1.5rem!important;
        padding: 0% !important;
        font-weight: 900!important;
        line-height: 35px!important;
    }
    .who_we_are_text{
        font-size: 0.7rem!important;
    }
   .who_we_are_heading{
    font-size: 0.9rem!important;
   }
    .who_we_are_padding{
        padding:10px 0px 10px 0px!important;
    }

}
@media screen and (max-width:810px) {
   
    .who_we_are_none_tablet{
        display: none;
    }
}
@media screen and (max-width:991px ) {
    .who_we_are_mobile{
        display: none;
      
    }
 
    .who_we_are_none_tablet{
        display: none;
    }
}


@media screen and (max-width:512px) {
    .who_we_are_mobile_responsive{
        padding: 10px !important;
    }
    .professional_text{
        margin: 0%!important;
    }
    .professional_text>div{
        font-size: 1.5rem!important;
        padding: 0% !important;
        font-weight: 900!important;
        line-height: 35px!important;
    }
    .who_we_are_text{
        font-size: 0.7rem!important;
    }
   .who_we_are_heading{
    font-size: 0.9rem!important;
   }
    .who_we_are_padding{
        padding:10px 0px 10px 0px!important;
    }

}
.who_we_are_box{
    position: absolute;
    margin-top: -3%;
    background: #6E379B;
    font-size:0.8rem ;
    width: 95%;
    border-radius: 4px;
}
#whoweare{
    padding-bottom: 20px;
    margin-bottom: 20px;
}
/*screens Greater than 992px - 1400px */
@media screen  and (min-width:992px) and (max-width:1400px) {
    .who_we_are_box{
        position: absolute;
        margin-top: -5.5%;
        background: #6E379B;
        font-size:0.8rem ;
        width: 95%;
    }
    #whoweare{
        padding-bottom: 20px;
        margin-bottom: 20px;
    }
}
/*screens Greater than 5001px - 6000px */
@media screen  and (min-width:5001px) and (max-width:6000px) {
    .who_we_are_box{
        position: absolute;
        margin-top: -1.2%;
        background: #6E379B;
        font-size:0.8rem ;
        width: 95%;
    }
    #whoweare{
        padding-bottom: 20px;
        margin-bottom: 20px;
    }
}
/*screens Greater than 4001px - 5000px */
@media screen  and (min-width:4001px) and (max-width:5000px) {
    .who_we_are_box{
        position: absolute;
        margin-top: -1.7%;
        background: #6E379B;
        font-size:0.8rem ;
        width: 95%;
    }
    #whoweare{
        padding-bottom: 20px;
        margin-bottom: 20px;
    }
}
/*screens Greater than 3001px - 4000px */
@media screen  and (min-width:3001px) and (max-width:4000px) {
    .who_we_are_box{
        position: absolute;
        margin-top: -2%;
        background: #6E379B;
        font-size:0.8rem ;
        width: 95%;
    }
    #whoweare{
        padding-bottom: 20px;
        margin-bottom: 20px;
    }
}
/*screens Greater than 2300px - 3000px */
@media screen  and (min-width:2300px) and (max-width:3000px) {
    .who_we_are_box{
        position: absolute;
        margin-top: -2.7%;
        background: #6E379B;
        font-size:0.8rem ;
        width: 95%;
    }
    #whoweare{
        padding-bottom: 20px;
        margin-bottom: 20px;
    }
}

/* Tablet Response Media Query */
@media screen and (max-width:991px)  {
    .who_we_are_box{
      
        margin-top: -20%!important;
        width:90%!important;
    }
    #whoweare{
        padding-bottom: 100px!important;
        margin-bottom: 100px!important;
    }
}
/* Mobile Device Media Query */
@media screen and (max-width:767px)  {
    .who_we_are_box{ 
        margin-top: -90%!important;
        width:90%!important;
    }
    #whoweare{
        padding-bottom: 200px!important;
        margin-bottom: 380px!important;
    }
}
@media screen and (max-width:550px)  {
    .who_we_are_box{ 
        margin-top: -110%!important;
        width:90%!important;
    }
    #whoweare{
        padding-bottom: 200px!important;
        margin-bottom: 380px!important;
    }
}
@media screen and (max-width:450px)  {
    .who_we_are_box{ 
        margin-top: -140%!important;
        width:90%!important;
    }
    #whoweare{
        padding-bottom: 200px!important;
        margin-bottom: 380px!important;
    }
}
@media screen and (max-width:400px)  {
    .who_we_are_box{ 
        margin-top: -150%!important;
        width:90%!important;
    }
    #whoweare{
        padding-bottom: 200px!important;
        margin-bottom: 380px!important;
    }
}
@media screen and (max-width:350px)  {
    .who_we_are_box{ 
        margin-top: -180%!important;
        width:90%!important;
    }
    #whoweare{
        padding-bottom: 200px!important;
        margin-bottom: 380px!important;
    }
}
@media screen and (max-width:300px)  {
    .who_we_are_box{ 
        margin-top: -200%!important;
        width:90%!important;
    }
    #whoweare{
        padding-bottom: 200px!important;
        margin-bottom: 380px!important;
    }
}
