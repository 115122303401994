body {
  margin: 0;
  padding:0;
  font-family: "Inter";
}

code {
  font-family: "Inter";
}

@media (min-width: 1200px) {
  .container{
      max-width: 1200px;
  }
}
.scroll-container {
  position: absolute;
  top: 0;
  right:20px;
  height: 100%;
}

.scroll-container:before {
  content: '';
  display: block;
  height: 100vh;
  pointer-events: none;
}

.scroll-container a {
  position: sticky;
  top: 88vh;
  right: 20px;
  cursor: pointer;
  font-size: 20px;
}
.sections{
  position: relative;
  scroll-behavior: smooth;
}
