@media screen and (max-width:767px) {
    
    .our_offering_heading{
        font-size: 1.5rem!important;
        font-weight: 900!important;
        padding: 0%!important;
        line-height: 40px!important;
    }
    .our_process_image{
        width:100%!important;
    }
    .list div{
        font-size: 0.5rem !important;
        
    }
}
#our-offerings{
    scroll-margin-top:100px;
}
#engage-us{
    scroll-margin-top:100px;
}